import {useEffect, useState, useContext, useRef} from "react";
import { StartContext } from "../App";

export default function SelectedItem({lotteryList}) {

    //抽選リストと抽選結果用のステート
    const [selected,setSelected] = useState("抽選結果");
    //抽選の切り替えタイミング(ms)
    const selectInterval = 50;
  
    //start/stopを管理するコンテキスト
    const start = useContext(StartContext);
  
  
    //そのほかの変数
    const intervalIdRef = useRef(null);//intervalIDを格納する変数
  
  
    //useEffect
    useEffect(() => {
      if (start) {
        if (!intervalIdRef.current && lotteryList.length > 0) {
          intervalIdRef.current = setInterval(() => {
            const randomIndex = Math.floor(Math.random() * lotteryList.length);
            setSelected(lotteryList[randomIndex]);
          }, selectInterval);
        }
      } else {
        if (intervalIdRef.current) {
          clearInterval(intervalIdRef.current);
          intervalIdRef.current = null;
        }
      }
  
      return () => {
        if (intervalIdRef.current) {
          clearInterval(intervalIdRef.current);
          intervalIdRef.current = null;
        }
      };
    }, [start, lotteryList]); // `lotteryList`を依存関係に追加


  return (

    <p className="selected-item">{selected}</p>

  )
}