import logo from './logo.svg';
import './App.css';
import './App-sp.css';
import LotteryList from './Components/LotteryList';
import { createContext, useState } from 'react';

//コンテキストを初期化
export const StartContext = createContext();

function App() {

  //スタート/ストップを管理するステート
  const [start,setStart] = useState(false);
  //ハンドラ
  const handleSelect = () => setStart(prev => !prev);//start/stopを切り替え

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div className="head-wrap">
          <h1>Flex Lottery</h1>
          <p className="head-sub">フレックス ロトリー</p>
        </div>
        

      </header>
      <main>
        <div className="inner-wrap">
          <StartContext.Provider value={start}>
            <LotteryList />
            <div className="btn-wrap">
              <button className="select-btn" onClick={handleSelect}>{!start ? "抽選開始" : "抽選終了"}</button>
            </div>
          </StartContext.Provider>
        </div>

      </main>
    </div>
  );
}

export default App;
