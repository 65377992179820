import { useState } from "react";



export default function TextArea({onChange}) {
  
  //state
  const [lotteryList,setLotteryList] = useState([]);

  //handler
  const handleLotteryList = (e) => {
    e.preventDefault();
    const inputVal = e.target.value.split('\n').filter((str) => str.trim() !== '');//空白行はフィルタする
    onChange(inputVal);//カンマで入力された値を区切る
  };

  return (
    <div className="form-wrap">
      <label>
        <span className="form-title">抽選リスト</span><br/>
        <textarea placeholder='抽選リストを入力'  onChange={handleLotteryList}></textarea>
      </label>
    </div>
  )
}

