import {useState} from "react";

export default function NumberInput() {

  //state
  const [lotteryCount,setLotteryCount] = useState(1);//抽選数の初期値は1

  //handler
  const handleCount = (e) => setLotteryCount(parseInt(e.target.value,10));

  return (
    <div className="form-wrap">
      <label>
        <span className="form-title">抽選数(機能未実装)</span><br/>
        <input type="number" placeholder='抽選数を入力' min={1} value={lotteryCount} onChange={handleCount} />
      </label>
    </div>
  );
}