import {useState} from "react";
import SelectedItem from "./selectedItem";
import TextArea from "./TextArea";
import NumberInput from "./NumberInput";

export default function  LotteryListItem({id}) {
  
  //定数
  const defaultStr = "抽選結果"
  //state
  const [lotteryList,setLotteryList] = useState([defaultStr]);

  return (
    <li className="lottery-list-item" data-item-id={id}>
      
      <SelectedItem lotteryList={lotteryList}/>

      <TextArea onChange={setLotteryList}/>

      <NumberInput />

    </li>

  )
}