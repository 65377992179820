import {useEffect, useState} from "react";
import LotteryListItem from "./LotteryListItem";
import PlusButton from "./PlusButton";

export default function LotteryList() {

  const initialCount = 3;
  const [items,setItems] = useState([]);


    //handler
    const addNewItem = () => {
      const newItem = (<LotteryListItem key={items.length} id={items.length} />)
      setItems([...items, newItem]);
    }

  //useEffect
  useEffect(() => {
    const initialItems = Array.from({ length: initialCount }, (_, index) => (<LotteryListItem key={index} id={index}/>)); // defaultItemsの配列を作成
    setItems(initialItems);

  },[])
  return (
    <>
      <ul className="lottery-list">
        {items}

        <PlusButton onClick={addNewItem}/>
      </ul>

    </>

  )
}